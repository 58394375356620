
import React from "react"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import SilderPricing from "@components/pageScore/pageContactCenterOmnicanal/components/silderPricing/silderPricing"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import data from "@components/pageScore/pageContactCenterOmnicanal/data"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import TitleUnderlineTestimony from "@components/pageTicker/titleUnderline/titleUnderlineTestimony"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import audifonos from "@components/sharedComponents/testimonies/images/iconos/audifonos.svg"
import salud from "@components/sharedComponents/testimonies/images/iconos/salud.svg"
import transportes from "@components/sharedComponents/testimonies/images/iconos/transporte.svg"
import ong from "@components/sharedComponents/testimonies/images/iconos/ayuda.svg"
import Integrations from "@components/pageScore/integrations/integrations"
import Start from "@components/pageScore/pageContactCenterOmnicanal/components/intro/intro"
import UseCases from "@components/pageScore/pageContactCenterOmnicanal/components/useCases/useCases"
import CardsCasos from "@components/pageScore/pageContactCenterOmnicanal/components/cardCasos/cardCasos"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
 


const StructureMainPage = ({ location }) => {
    //velocidad de entrada de textos y animaciones para desktop y mobile
    const windowsWidth = true

    const titleTestimony = <p>
        Con Beex, Contact Centers en todo el mundo están realmente mejorando  <TitleUnderlineTestimony underline="sus resultados" /></p>


    const iconsSilder = i => {
        switch (i) {
            case 0:
                return finanzas
            case 1:
                return audifonos
            case 2:
                return salud
            case 3:
                return transportes
            case 4:
                return ong
            default:
                return ""
        }
    }
    const titleSilder = i => {
        switch (i) {
            case 0:
                return "Finanzas"
            case 1:
                return "Contact Center & BPO"
            case 2:
                return "Salud"
            case 3:
                return "Transportes"
            case 4:
                return "ONG"
            default:
                return "Conoce otros casos de éxito"
        }
    }

    return (
        <>
            <div className="fnd">
                <BannerCookies />
        
                <Header
                    path="/"
                    location={location}
                    windowsWidth={windowsWidth}
                />
                <div className="container">
                    <Start data={data.start} location={location} />
                    <UseCases data={data.modules} location={location} data1={data.useCases} />
                    <BannerCTA
                        title="Empieza a mejorar la comunicación con tus clientes, ahora mismo"
                        text="Accede a una demo total y comprueba como te ayudaría nuestra tecnología omnicanal."
                        link="/hablemos/"
                        textButton="Comunicarme con ventas"
                    />
                    <CardsCasos data={data.cardCasos} />
                    <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
                    <SilderPricing data={data.pricing} />
                    <Integrations location={location} />
                    <Footer data={data.footer} location={location} />
                    <FooterMobile data={data.footer} location={location} />
                </div>
            </div>
        </>
    )
}

export default StructureMainPage
